import React from "react"
import Helmet from "react-helmet"

export default ({ metadata }) => (
  <Helmet>
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <title>{metadata.title}</title>
    <meta charset="UTF-8" />
    <meta name="description" content={metadata.description} />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content={metadata.title} />
    <meta property="og:description" content={metadata.description} />
    <meta property="og:url" content={metadata.url} />
    <meta property="og:site_name" content="Advanx Health" />
    <meta
      property="article:publisher"
      content="https://www.facebook.com/advanxhealthmy/"
    />
    <meta property="og:image" content={metadata.image} />
    <link rel="canonical" href={metadata.url} />

    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="robots" content="all,follow" />
    <link
      href="https://fonts.googleapis.com/css?family=Karla:300,400,500,600,700|Nunito:300,400,500,600,800"
      rel="stylesheet"
    />

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" integrity="sha512-SfTiTlX6kk+qitfevl/7LibUOeJWlt9rbyDn92a1DqWOw9vWG2MFoays0sgObmWazO5BQPiFucnnEAjpAB+/Sw==" crossorigin="anonymous" referrerpolicy="no-referrer" />
  </Helmet>
)
